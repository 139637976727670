/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';

/***************************************************************************************************
 * APPLICATION IMPORTS
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).global = window;
